'use strict';

angular.module('mvpcarrefourApp')
  .factory('Category', ['$resource','$http', '$q',
    function($resource, $http, $q) {

    return {
        all: $resource('/api/categories', {},
          { get: { method:'GET', isArray: true }},
          { create: { method:'POST' }}),
        one: $resource('/api/categories/:id', { id: '@id'},
          { get: { method:'GET', isArray: false }},
          { update: { method: 'PUT' }}),
        list: (filter) => {
          var deffered = $q.defer();
            $http.get('api/categories', {params: filter}).then(response => {
              var _categories = response.data;
              deffered.resolve(_categories);
            });
          return deffered.promise;
         },
        create: (data, callback) => {
          $http.post('/api/categories', data).then(response => {
            callback(response);
          })
        },
        update: (data, callback) => {
          $http.put('/api/categories/' + data._id, data).then(response => {
            callback(response);
          })
        },
        delete: (data, callback) => {
          $http.delete('/api/categories/' + data._id).then(response => {
            callback(response);
          });
        }
      };
    }
  ]).factory('Product', function($resource) {
    return $resource('/api/products/:id/:controller/:limit', {
      id: '@_id'
    }, {
      'update': { method: 'PUT' },
      'category': {
        method: 'GET',
        isArray: true,
        params: {
          controller: 'category'
        }
      },
      'search': {
        method: 'GET',
        isArray: true,
        params: {
          controller: 'search'
        }
      },
      'review': {
        method: 'POST',
        params: {
          controller: 'reviews',
          limit: null
        }
      },
      'productById': {
        method: 'GET',
        isArray: true,
        params: {
          controller: 'productById',
          limit: null
        }
      }
    });
  });
